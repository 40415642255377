import {
  isRouteErrorResponse,
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useNavigation,
  useRouteError,
} from "react-router";
import { useTranslation } from "react-i18next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export let loader = async ({ request, context }: LoaderFunctionArgs) => {
  const { env } = context.cloudflare;

  return { GOOGLE_RECAPTCHA_CLIENT: env.GOOGLE_RECAPTCHA_CLIENT };
};

export default function AuthLayout() {
  let navigation = useNavigation();
  const { t, i18n } = useTranslation("translation");
  const { GOOGLE_RECAPTCHA_CLIENT } = useLoaderData();
  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={GOOGLE_RECAPTCHA_CLIENT}
        language={i18n.language}
      >
        {navigation.state === "loading" || navigation.state === "submitting" ? (
          <section className=" h-full w-full border-gray-25 dark:bg-black overflow-hidden">
            <div className="w-full lg:min-h-screen p-4 lg:p-0">
              <main
                aria-label="Main"
                className="flex items-center max-w-xl lg:min-h-screen  justify-center px-2 py-2 sm:px-12  lg:py-6 lg:px-8 m-auto"
              >
                <div className="flex flex-col m-auto">
                  <div className="flex h-full">
                    <div className="flex justify-center flex-col gap-4">
                      <progress className="progress progress-primary w-56 m-auto dark:progress-secondary"></progress>
                      <h1 className="text-primary text-sm text-center dark:text-secondary">
                        {t("loginLoading")}
                      </h1>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </section>
        ) : (
          <Outlet />
        )}
      </GoogleReCaptchaProvider>
    </>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>Oops</h1>
        <p>Status: {error.status}</p>
        <p>{error.data.message}</p>
      </div>
    );
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  let errorMessage =
    (error as { message?: string })?.message || "Unknown error";

  return (
    <div className="grid h-screen place-items-center">
      <div className="flex flex-col items-center max-w-lg">
        <div className="alert alert-error text-center flex flex-col gap-4">
          <h1>Uh oh ...</h1>
          <p>Something went wrong.</p>
          {errorMessage}
          <p>Refresh the page and try again</p>
          <a
            onClick={() => window.location.reload()}
            className="btn w-full whitespace-nowrap flex flex-col px-4"
          >
            Refresh
          </a>
        </div>
      </div>
    </div>
  );
}
